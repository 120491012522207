<template>
  <div class="chartMianbox pr">
    <div :id="refName"></div>
    
    <div class="noData" v-show='!isShow'>
      <img :src="noDataUrl" alt="">
      <p>暂无数据</p>
    </div>
  
    <p class="indicate_box" v-if="isWind && isShow"> <img :src="jtIcon" alt="">北风</p>
       
  </div>
</template>

<script>
import { optionMaxMinLine,optionWindLine } from '@/libs/echartsOption'
export default {
   props:{
       options: {
           type: Object,
           default: () => { return {}}
       },   
       refName: String,
   },
   data(){
       return{
         charts: null,
         noDataUrl: require('@/assets/imgs/noData/no_data2.png'),
         jtIcon: require('@/assets/imgs/monitoring/default/jt.png')
       }
      
   },
   watch:{
     options: function(val){
      console.log(val)
       if(val.yAxis && val.yAxis.length) {
           if(val.paramName == '风向')
            this.charts.setOption(optionWindLine(val),true)
           else 
            this.charts.setOption(optionMaxMinLine(val),true) 
       }else{
          this.charts.clear()
       }
        
     },
     
   },
   computed:{
       isShow(){
         
            let data = this.options.yAxis
            return data && data.length ? true : false
       },
       isWind(){
          return this.options && this.options.paramName == '风向'? true : false
       }  
   },
   mounted(){
     
         this.echartInfo()
   },
   methods:{
     echartInfo(){
       let myChart = this.$echarts.init(document.getElementById(this.refName))
        if(this.isShow){
            let options = this.options

            if( options.paramName == '风向' ){
                myChart.setOption(optionWindLine(options),true)
            }else{
              myChart.setOption(optionMaxMinLine(options),true)
            }
              
            window.addEventListener("resize", function () {
                  myChart.resize();          
            })
        }
       
        this.charts = myChart
     },
 
   }
}
</script>

<style lang="scss" scoped>
  .chartMianbox{
      width: 100%;
      height: 100%;
     
  }
  .indicate_box{
     position: absolute;
     right: 10px;
     top: 5px;
     font-size: 12px;
     display: flex;
     align-items: center;
     & > img {
       width: 15px;
       height: 15px;
       transform: rotate(-90deg);
       margin-right: 5px;
     }
  }
  .chartMianbox> div{
        width: 100%;
        height: 100%; 
        
  }
  .noData{
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #999;
    font-size: 14px;
    & > img {
       width: 120px;
       margin-right: 25px;
    }
  }
</style>